/**
 * @file 页脚数据
 * @author FengGuang(fengguang01@baidu.com)
 */

export interface IFooterDataItem {
    name: string;
    url: string;
    target?: string;
}

export interface IFooterDataGroup {
    groupName: string;
    list: IFooterDataItem[];
}  

const footerData: IFooterDataGroup[] = [
    {
        groupName: '产品',
        list: [
            {
                name: 'PaddleX',
                url: 'https://github.com/PaddlePaddle/PaddleX',
                target: '_blank'
            },
            {
                name: 'Paddle Lite',
                url: '/paddle/paddlelite'
            },
            {
                name: 'ERNIE',
                url: '/paddle/ernie'
            },
            {
                name: '更多',
                url: '/tools'
            }
        ]
    },
    {
        groupName: '资源',
        list: [
            {
                name: '安装',
                url: '/install/quick'
            },
            {
                name: '教程',
                url: '/tutorials'
            },
            {
                name: '文档',
                url: '/documentation/docs/zh/user_guides/index_cn.html?from=paddlefooter',
                target: '_blank'
            },
            {
                name: '应用案例',
                url: '/customercase'
            }
        ]
    },
    {
        groupName: '友情链接',
        list: [
            {
                name: 'AI开放平台',
                url: 'https://ai.baidu.com/',
                target: '_blank'
            },
            {
                name: 'AI Studio',
                url: 'https://aistudio.baidu.com/',
                target: '_blank'
            },
            {
                name: 'EasyDL',
                url: 'http://ai.baidu.com/easydl/',
                target: '_blank'
            },
            {
                name: 'BML',
                url: 'https://ai.baidu.com/bml/',
                target: '_blank'
            },
            {
                name: '百度开发者中心',
                url: 'https://developer.baidu.com/',
                target: '_blank'
            }
        ]
    },
    {
        groupName: '联系我们',
        list: [
            {
                name: 'GitHub',
                url: 'http://github.com/paddlepaddle/paddle',
                target: '_blank'
            },
            {
                name: 'Email',
                url: 'mailto:Paddle-better@baidu.com'
            },
            {
                name: 'Gitee',
                url: 'https://gitee.com/paddlepaddle',
                target: '_blank'
            }
        ]
    }
];

export default footerData;
